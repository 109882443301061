import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckCircle, ArrowLeft, BookOpen } from 'lucide-react';
import SEO from '@/components/SEO';

const PriceInquirySuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        titleKey={t("seo.pages.priceInquirySuccess.title")}
        descriptionKey={t("seo.pages.priceInquirySuccess.description")}
        path="/price-inquiry-success"
      />
      
      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-2xl mx-auto text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
            
            <h1 className="text-3xl md:text-4xl font-bold pb-3 mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
              {t("priceInquirySuccess.title")}
            </h1>
            
            <p className="text-gray-300 mb-8">
              {t("priceInquirySuccess.message")}
            </p>

            <div className="grid gap-4 md:grid-cols-2 max-w-md mx-auto">
              <Link
                to="/"
                className="flex items-center justify-center gap-2 px-6 py-3 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors"
              >
                <ArrowLeft className="w-4 h-4" />
                {t("priceInquirySuccess.buttons.home")}
              </Link>
              
              <Link
                to="/blog"
                className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 rounded-lg transition-all"
              >
                <BookOpen className="w-4 h-4" />
                {t("priceInquirySuccess.buttons.blog")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceInquirySuccess; 