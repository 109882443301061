/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, useScroll, useTransform } from 'framer-motion';
import NetworkAnimation from '@/components/NetworkAnimation';
import ServiceCard from '@/components/ServiceCard';
import { Shield, Award, Users, Zap, Brain } from 'lucide-react';
import SEO from '@/components/SEO';

interface Service {
  titleKey: string;
  descriptionKey: string;
  icon: React.ReactNode;
  link: string;
}

interface Feature {
  titleKey: string;
  descriptionKey: string;
  icon: React.ReactNode;
  link: string;
}

const services: Service[] = [
  {
    titleKey: 'services.microsoft365.title',
    descriptionKey: 'services.microsoft365.subtitle',
    icon: <Shield className="w-6 h-6" />,
    link: '/services/microsoft-365'
  },
  {
    titleKey: 'services.aiIntegration.title',
    descriptionKey: 'services.aiIntegration.subtitle',
    icon: <Award className="w-6 h-6" />,
    link: '/services/ai-integration'
  },
  {
    titleKey: 'services.documentManagement.title',
    descriptionKey: 'services.documentManagement.subtitle',
    icon: <Users className="w-6 h-6" />,
    link: '/services/document-management'
  }
];

const features: Feature[] = [
  {
    titleKey: 'home.features.items.ai.title',
    descriptionKey: 'home.features.items.ai.description',
    icon: '🚀',
    link: '/services/ai-integration'
  },
  {
    titleKey: 'home.features.items.docs.title',
    descriptionKey: 'home.features.items.docs.description',
    icon: '💡',
    link: '/services/document-management'
  },
  {
    titleKey: 'home.features.items.sharepoint.title',
    descriptionKey: 'home.features.items.sharepoint.description',
    icon: '🤝',
    link: '/services/sharepoint-development'
  }
];

const Home = () => {
  const { t } = useTranslation();
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  const memoizedServices = useMemo(() => services, []);
  const handleScroll = useCallback(() => {
    // scroll handling logic
  }, []);

  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey="seo.pages.home.title"
      descriptionKey="seo.pages.home.description"
      path="/"
      defaultTitle="seo.defaultTitle"
      defaultDescription="seo.defaultDescription"
    />
    <div className="relative moving-element min-h-screen bg-black text-white">
      {/* Background Animation - Only visible in hero and footer */}
      <motion.div 
        style={{ opacity }}
        className=" inset-0 z-0"
      >
        <Suspense fallback={<div>Loading...</div>}>
          <NetworkAnimation />
        </Suspense>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black" />
      </motion.div>

      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4 z-10 py-32"
        >
          <div className="text-center">
 
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r pb-5 from-blue-500 via-purple-500 to-cyan-500">
                {t('home.hero.title.line1')}
                <br />
                {t('home.hero.title.line2')}
              </h1>
            </motion.div>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto"
            >
              {t('home.hero.subtitle')}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="space-x-4"
            >
              <Link
                to="/booking"
                className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-4 px-8 rounded-lg transition duration-300 inline-flex items-center"
              >
                {t('home.hero.buttons.bookDemo')}
                <Zap className="ml-2 w-5 h-5" />
              </Link>
              <Link
                to="/services"
                className="bg-gray-800/50 backdrop-blur-sm hover:bg-gray-700 text-white font-bold py-4 px-8 rounded-lg transition duration-300 inline-flex items-center border border-gray-700"
              >
                {t('home.hero.buttons.learnMore')}
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </section>



      {/* Why Choose Us Section */}
      <section className="relative py-24 contain-layout contain-paint">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="p-6 rounded-xl border border-gray-800 bg-black/30 backdrop-blur-sm"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-white mb-2">
                  {t(feature.titleKey)}
                </h3>
                <p className="text-gray-400">
                  {t(feature.descriptionKey)}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Overview Section - With solid background */}
      <section className="relative py-32 bg-black">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/10 to-purple-900/10" />
        <div className="container mx-auto px-4 relative">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl md:text-5xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500"
          >
            {t('home.features.title')}
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xl text-gray-400 text-center mb-16 max-w-3xl mx-auto"
          >
            {t('home.features.subtitle')}
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {memoizedServices.map((service, index) => (
              <ServiceCard
                key={index}
                title={t(service.titleKey)}
                description={t(service.descriptionKey)}
                icon={service.icon}
                link={service.link}
              />
            ))}
          </div>
        </div>
      </section>

      
            {/* New Impact Statement Section */}
            <section className="relative py-24 bg-black">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center max-w-4xl mx-auto"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-cyan-500">
            {t('home.midCta.title')}
            </h2>
            <div className="flex justify-center gap-4">
              <Link
                to="/booking"
                className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
              >
              {t('home.midCta.button')}
              </Link>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section - With gradient overlay */}
      <section className="relative py-32 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="relative">
            {/* Connecting Lines */}
            <svg className="absolute inset-0 w-full h-full" style={{ zIndex: 0 }}>
              <path
                d="M0 0 L100 100"
                className="stroke-blue-500/20 stroke-2"
                strokeDasharray="5,5"
              />
            </svg>
            
            <div className="grid grid-cols-2 md:grid-cols-3  gap-8 relative z-10">
              {Object.entries(t('home.stats', { returnObjects: true })).map(([key, stat]: [string, any], index) => (
                <motion.div
                  key={key}
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ 
                    delay: index * 0.1,
                    type: "spring",
                    stiffness: 100
                  }}
                  className="group"
                >
                  <div className="relative aspect-square">
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-full blur-2xl group-hover:blur-3xl transition-all duration-300" />
                    <div className="relative h-full rounded-full border border-gray-800 bg-black/50 backdrop-blur-sm flex flex-col items-center justify-center p-6">
                      <div className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                        {stat.number}
                      </div>
                      <div className="text-gray-400 text-center mt-2">{stat.label}</div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>


      {/* CTA Section - With animated background */}
      <section className="relative py-32 overflow-hidden">
        <div className="absolute inset-0">
          <Suspense fallback={<div>Loading...</div>}>
            <NetworkAnimation />
          </Suspense>
          <div className="absolute inset-0 bg-gradient-to-b from-black via-blue-900/20 to-black" />
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="container mx-auto px-4 text-center relative z-10"
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true }}
            className="mb-8"
          >
            <Brain className="w-16 h-16 mx-auto text-blue-500" />
          </motion.div>
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-8">
            {t('home.cta.title')}
          </h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            {t('home.cta.subtitle')}
          </p>
          <Link
            to="/booking"
            className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-4 px-8 rounded-lg transition duration-300 inline-flex items-center"
          >
            {t('home.cta.button')}
            <Zap className="ml-2 w-5 h-5" />
          </Link>
        </motion.div>
      </section>
    </div>
    </>
  );
};



export default Home;