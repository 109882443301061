import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';


const Services: React.FC = () => {
  const { t } = useTranslation(); 

  const services = [
    {
      title: t('services.sharepoint.title'),
      description: t('services.sharepoint.subtitle'),
      href: '/services/sharepoint-development',
      icon: '📄',
      gradient: 'from-blue-500 to-cyan-500',
    },
    {
      title: t('services.powerApps.title'),
      description: t('services.powerApps.subtitle'),
      href: '/services/power-apps',
      icon: '⚡',
      gradient: 'from-purple-500 to-pink-500',
    },
    {
      title: t('services.powerAutomate.title'),
      description: t('services.powerAutomate.subtitle'),
      href: '/services/power-automate',
      icon: '🔄',
      gradient: 'from-orange-500 to-red-500',
    },
    {
      title: t('services.documentManagement.title'),
      description: t('services.documentManagement.subtitle'),
      href: '/services/document-management',
      icon: '📁',
      gradient: 'from-green-500 to-emerald-500',
    },
    {
      title: t('services.aiIntegration.title'),
      description: t('services.aiIntegration.subtitle'),
      href: '/services/ai-integration',
      icon: '🤖',
      gradient: 'from-indigo-500 to-purple-500',
    },
    {
      title: t('services.microsoft365.title'),
      description: t('services.microsoft365.subtitle'),
      href: '/services/microsoft-365',
      icon: '☁️',
      gradient: 'from-blue-600 to-indigo-600',
    },
  ];

  return (
      <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.pages.services.title")}
      descriptionKey={t("seo.pages.services.description")}
      path="/services"
    />
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-3xl" />
        <div className="container mx-auto px-4 py-20 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto text-center mb-16"
          >
            <h1 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 mb-6 pb-4">
              {t('services.hero.title')}
            </h1>
            <p className="text-xl md:text-2xl text-gray-300 leading-relaxed">
              {t('services.hero.subtitle')}
            </p>
          </motion.div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="container mx-auto px-4 -mt-20 pb-20">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.href}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Link to={service.href} className="group block h-full">
                <div className="h-full p-8 bg-gray-800/50 backdrop-blur-sm rounded-2xl border border-gray-700/50 hover:border-gray-600/50 transition-all duration-300">
                  <div className="flex items-center justify-between mb-4">
                    <div className="text-4xl">{service.icon}</div>
                    <div
                      className={`bg-gradient-to-r ${service.gradient} p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                    >
                      <ArrowRight className="w-4 h-4 text-white" />
                    </div>
                  </div>
                  <h2 className="text-xl font-semibold text-white mb-3 group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-blue-400 group-hover:to-purple-400 transition-all duration-300">
                    {service.title}
                  </h2>
                  <p className="text-gray-400 leading-relaxed">{service.description}</p>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="mt-20 text-center"
        >
          <div className="max-w-4xl mx-auto bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 p-12 rounded-2xl border border-gray-700/50 backdrop-blur-sm">
            <h2 className="text-3xl font-bold text-white mb-4">
              {t('services.cta.title')}
            </h2>
            <p className="text-xl text-gray-300 mb-8">{t('services.cta.subtitle')}</p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all group"
            >
              {t('services.cta.button')}
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
    </>
  );
};

export default Services;
