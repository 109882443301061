import { format } from 'date-fns';
import { sv, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

export const useFormattedDate = () => {
  const { i18n } = useTranslation();

  const getLocale = () => {
    switch (i18n.language) {
      case 'sv':
        return sv;
      default:
        return enUS;
    }
  };

  const formatDate = (date: Date, formatString: string) => {
    return format(date, formatString, { locale: getLocale() });
  };

  return formatDate;
}; 