import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import SEO from "@/components/SEO";
import { priceInquiryService } from '@/services/priceInquiryService';
import { useNavigate } from 'react-router-dom';

const Prices = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    employees: "",
    message: "",
    selectedService: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const { name, email, company, employees } = formData;

    const isValid = Boolean(
      name.trim() && email.trim() && company.trim() && employees.trim()
    );

    setIsFormValid(isValid);
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid) return;

    try {
      await priceInquiryService.submitInquiry(formData);
      navigate('/price-inquiry-success');
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,

      [e.target.name]: e.target.value,
    });
  };

  const services = [
    {
      id: "ai",
      title: t("prices.ai.title"),
      description: t("prices.ai.description"),
      advantages: t("prices.ai.advantages", { returnObjects: true }),
    },
    {
      id: "document",
      title: t("prices.document.title"),
      description: t("prices.document.description"),
      advantages: t("prices.document.advantages", { returnObjects: true }),
    },
    {
      id: "sharepoint",
      title: t("prices.sharepoint.title"),
      description: t("prices.sharepoint.description"),
      advantages: t("prices.sharepoint.advantages", { returnObjects: true }),
    },
  ];

  const handleServiceSelect = (serviceId: string) => {
    setFormData(prev => ({
      ...prev,
      selectedService: serviceId
    }));
    const element = document.getElementById("price-form");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <SEO
        titleKey={t("seo.pages.prices.title")}
        descriptionKey={t("seo.pages.prices.description")}
        path="/prices"
      />

      <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold mb-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
              {t("prices.title")}
            </h1>

            <div className="text-center mb-12">
              <p className="text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400">
                {t("prices.slogan")}
              </p>
            </div>

            <h4 className="text-xl md:text-2xl mb-12 text-center text-gray-400">
              {t("prices.subtitle")}
            </h4>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="backdrop-blur-lg bg-slate-900/50 rounded-2xl p-8 border border-gray-800 hover:border-blue-500 transition-all duration-300 shadow-2xl flex flex-col cursor-pointer"
                  onClick={() => handleServiceSelect(service.id)}
                >
                  <h3 className="text-xl font-semibold mb-4 text-blue-300">
                    {service.title}
                  </h3>

                  <p className="text-gray-400 mb-6">{service.description}</p>

                  <div className="flex-grow">
                    <h4 className="text-sm font-medium text-purple-400 mb-3">
                      {t("prices.advantages.title")}
                    </h4>

                    <ul className="space-y-2 mb-6">
                      {Array.isArray(service.advantages) && service.advantages.map((advantage, i) => (
                        <li key={i} className="flex items-start text-gray-300">
                          <span className="text-green-400 mr-2">✓</span>
                          <span className="leading-tight">{advantage}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <button
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-lg font-medium 
                             hover:from-blue-600 hover:to-purple-700 transition duration-200 mt-auto"
                  >
                    {t("prices.advantages.selectService")}
                  </button>
                </div>
              ))}
            </div>

            <div className="max-w-4xl mx-auto mb-16">
              <div className="backdrop-blur-lg bg-gradient-to-r from-blue-600/10 to-purple-600/10 rounded-2xl p-8 border border-blue-500/20 shadow-2xl text-center">
                <h3 className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                  {t("prices.demo.title")}
                </h3>

                <p className="text-gray-300 mb-6">
                  {t("prices.demo.description")}
                </p>

                <a
                  href="/booking"
                  className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white px-8 py-3 rounded-lg font-medium 
                           hover:from-blue-600 hover:to-purple-700 transition duration-200"
                >
                  {t("prices.demo.button")}
                </a>
              </div>
            </div>

            <div id="price-form" className="max-w-4xl mx-auto">
              <div className="backdrop-blur-lg bg-slate-900/50 rounded-2xl p-8 shadow-2xl">
                <h2 className="text-2xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                  {t("prices.form.title")}
                </h2>

                {submitted ? (
                  <div className="mt-4 p-4 bg-green-500/20 border border-green-500 rounded-lg text-green-300">
                    <p>{t("prices.form.success")}</p>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid md:grid-cols-2 gap-6">
                      <div className="relative">
                        <label className="text-sm font-medium text-blue-300 block mb-2">
                          {t("prices.form.name")}{" "}
                          <span className="text-red-500">*</span>
                        </label>

                        <input
                          type="text"
                          name="name"
                          required
                          value={formData.name}
                          onChange={handleChange}
                          className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                        />
                      </div>

                      <div className="relative">
                        <label className="text-sm font-medium text-blue-300 block mb-2">
                          {t("prices.form.email")}{" "}
                          <span className="text-red-500">*</span>
                        </label>

                        <input
                          type="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                          className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                        />
                      </div>
                    </div>

                    <div className="grid md:grid-cols-2 gap-6">
                      <div className="relative">
                        <label className="text-sm font-medium text-blue-300 block mb-2">
                          {t("prices.form.company")}{" "}
                          <span className="text-red-500">*</span>
                        </label>

                        <input
                          type="text"
                          name="company"
                          required
                          value={formData.company}
                          onChange={handleChange}
                          className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                        />
                      </div>

                      <div className="relative">
                        <label className="text-sm font-medium text-blue-300 block mb-2">
                          {t("prices.form.employees")}{" "}
                          <span className="text-red-500">*</span>
                        </label>

                        <input
                          type="number"
                          name="employees"
                          required
                          value={formData.employees}
                          onChange={handleChange}
                          className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                        />
                      </div>
                    </div>

                    <div className="relative">
                      <label className="text-sm font-medium text-blue-300 block mb-2">
                        {t("prices.form.message")}
                      </label>

                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={4}
                        className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                      />
                    </div>

                    {formData.selectedService && (
                      <div className="relative">
                        <label className="text-sm font-medium text-blue-300 block mb-2">
                          {t("prices.form.selectedService")}
                        </label>
                        <input
                          type="text"
                          value={services.find(s => s.id === formData.selectedService)?.title || ""}
                          disabled
                          className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3"
                        />
                      </div>
                    )}

                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={!isFormValid}
                        className={`w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg font-medium transition duration-200 ${
                          isFormValid
                            ? "hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900"
                            : "opacity-50 cursor-not-allowed"
                        }`}
                      >
                        {t("prices.form.submit")}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prices;
