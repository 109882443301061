import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

const BookingSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingDetails } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white pt-16">
      <div className="container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto text-center"
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200, damping: 20 }}
            className="inline-block mb-8"
          >
            <CheckCircle className="w-20 h-20 text-green-400" />
          </motion.div>

          <h1 className="text-4xl font-bold mb-4">
            {t('bookingSuccess.title')}
          </h1>
          
          <p className="text-xl text-gray-400 mb-8">
            {t('bookingSuccess.message')}
          </p>

          {bookingDetails && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="bg-gray-800/50 rounded-lg p-6 mb-8"
            >
              <p className="text-gray-300">
                {t('bookingSuccess.details.datetime')}: {bookingDetails.date} {bookingDetails.time}
              </p>
              <p className="text-gray-300">
                {t('bookingSuccess.details.subject')}: {bookingDetails.subject}
              </p>
            </motion.div>
          )}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="flex justify-center gap-4"
          >
            <button
              onClick={() => navigate('/')}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 transition-all"
            >
              {t('bookingSuccess.buttons.home')}
            </button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default BookingSuccess;