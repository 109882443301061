import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { 
  Code2, 
  Fingerprint, 
  Zap, 
  Brain, 
  Rocket,
  Coffee,
  Users,
  Timer
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SEO from '@/components/SEO';

const About = () => {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 200], [1, 0]);
  const { t } = useTranslation();

  

  const stats = [
    { value: t('about.stats.experience.value'), label: t('about.stats.experience.label'), icon: Timer },
    { value: t('about.stats.projects.value'), label: t('about.stats.projects.label'), icon: Rocket },
    { value: t('about.stats.automation.value'), label: t('about.stats.automation.label'), icon: Zap },
    { value: t('about.stats.availability.value'), label: t('about.stats.availability.label'), icon: Coffee }
  ];

  const expertise = [
    {
      title: t('about.expertise.items.fullStack.title'),
      description: t('about.expertise.items.fullStack.description'),
      icon: Code2,
      color: "text-blue-500"
    },
    {
      title: t('about.expertise.items.automation.title'),
      description: t('about.expertise.items.automation.description'),
      icon: Brain,
      color: "text-purple-500"
    },
    {
      title: t('about.expertise.items.problemSolving.title'),
      description: t('about.expertise.items.problemSolving.description'),
      icon: Fingerprint,
      color: "text-cyan-500"
    }
  ];

  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.pages.about.title")}
      descriptionKey={t("seo.pages.about.description")}
      path="/about"
    />  


    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">

      <motion.div 
        style={{ opacity }}
        className="fixed top-0 left-0 w-full h-screen pointer-events-none bg-gradient-to-b from-black/50 to-transparent"
      />
      
      {/* Hero Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="container mx-auto px-4 pt-32 pb-20"
      >
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mb-6 inline-block"
          >
            <Users className="w-16 h-16 text-blue-500 mx-auto" />
          </motion.div>
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="text-5xl font-bold mb-6 pb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-cyan-500"
          >
            {t('about.hero.title')}
          </motion.h1>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-xl text-gray-300 leading-relaxed"
          >
            {t('about.hero.subtitle')}
          </motion.p>
        </div>
      </motion.div>

      {/* Stats Section - Circular Style */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-full blur-xl group-hover:blur-2xl transition-all duration-300" />
                <div className="relative aspect-square rounded-full border-4 border-gray-800 p-6 flex flex-col items-center justify-center text-center hover:border-blue-500/50 transition-colors duration-300">
                  <stat.icon className="w-8 h-8 text-blue-500 mb-2" />
                  <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">
                    {stat.value}
                  </div>
                  <div className="text-sm text-gray-400 mt-1">{stat.label}</div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Expertise Section */}
      <div className="container mx-auto px-4 py-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500"
        >
          {t('about.expertise.title')}
        </motion.h2>
        <div className="grid md:grid-cols-3 gap-8">
          {expertise.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-xl blur-xl group-hover:blur-2xl transition-all duration-300" />
              <Card className="relative p-6 bg-gray-900/50 border-gray-800 hover:border-blue-500/50 transition-all duration-300">
                <item.icon className={`w-10 h-10 ${item.color} mb-4`} />
                <h3 className="text-xl font-semibold mb-3 text-white">{item.title}</h3>
                <p className="text-gray-400 leading-relaxed">{item.description}</p>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Why Work With Us Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="container mx-auto px-4 py-24"
      >
        <div className="relative">
          {/* Background decorative elements */}
          <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:40px_40px]" />
          <div className="absolute -left-20 -top-20 w-72 h-72 bg-purple-500/10 rounded-full blur-3xl" />
          <div className="absolute -right-20 -bottom-20 w-72 h-72 bg-blue-500/10 rounded-full blur-3xl" />

          <Card className="relative p-12 bg-gradient-to-br from-gray-900/95 to-gray-800/95 border border-gray-800/50 backdrop-blur-sm rounded-2xl overflow-hidden">
            <div className="max-w-4xl mx-auto text-center relative z-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="mb-12"
              >
                <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-indigo-400">
                  {t('about.whyUs.title')}
                </h2>
                <div className="w-24 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mx-auto rounded-full" />
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
                className="space-y-8"
              >
                <p className="text-lg text-gray-300 leading-relaxed">
                  {t('about.whyUs.content.paragraph1')}
                </p>
                <p className="text-lg text-gray-300 leading-relaxed">
                  {t('about.whyUs.content.paragraph2')}
                </p>
              </motion.div>
            </div>
          </Card>
        </div>
      </motion.div>
    </div>
    </>
  );
};

export default About;