import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface SEOProps {
  titleKey?: string;  // i18n key for title
  descriptionKey?: string;  // i18n key for description
  path?: string;
  defaultTitle?: string;  // fallback title
  defaultDescription?: string;  // fallback description
}

const SEO: React.FC<SEOProps> = ({ 
  titleKey = '',
  descriptionKey = '',
  path = '',
  defaultTitle = '',
  defaultDescription = ''
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const siteUrl = 'https://intra-solutions.com';
  const fullUrl = `${siteUrl}${path}`;

  // Get translated content with fallbacks
  const title = t(titleKey);
  const description = t(descriptionKey);

  // Language-specific keywords
  const getKeywords = () => {
    switch(currentLang) {
      case 'sv':
        return "Microsoft 365, SharePoint, Power Apps, Power Automate, programvaruutveckling, företagslösningar, IT-konsulting, Sverige, AI-utveckling";
      case 'no':
        return "Microsoft 365, SharePoint, Power Apps, Power Automate, programvareutvikling, forretningsløsninger, IT-konsulenting, Norge, AI-utvikling";
      default:
        return "Microsoft 365, SharePoint, Power Apps, Power Automate, software development, business solutions, IT consulting, UK, AI-development";
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <html lang={currentLang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:site_name" content="Intra Solutions" />
      <meta property="og:locale" content={currentLang === 'en' ? 'en_GB' : currentLang === 'sv' ? 'sv_SE' : 'nb_NO'} />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      
      {/* Additional SEO Meta Tags */}
      <meta name="keywords" content={getKeywords()} />
      <meta name="robots" content="index, follow" />
      
      {/* Language Alternates */}
      <link rel="alternate" href={`${siteUrl}/sv${path}`} hrefLang="sv-SE" />
      <link rel="alternate" href={`${siteUrl}/en${path}`} hrefLang="en-GB" />
      <link rel="alternate" href={`${siteUrl}/no${path}`} hrefLang="nb-NO" />
      <link rel="alternate" href={`${siteUrl}${path}`} hrefLang="x-default" />
      
      {/* Schema.org JSON-LD with language-specific content */}
      <script type="application/ld+json">
        {JSON.stringify(
        {
          "@context": "https://schema.org",
          "@type": "SoftwareCompany",
          "name": "Intra Solutions",
          "description": description,
          "url": siteUrl,
          "areaServed": ["GB", "SE", "NO"],
          "serviceType": [
            t('seo.services.microsoft365.solution'),
            t('seo.services.sharepoint.solution'),
            t('seo.services.powerApps.solution'),
            t('seo.services.powerAutomate.solution'),
            t('seo.services.documentManagement.solution'),
            t('seo.services.aiIntegration.solution')
          ]
        }
        )}
      </script>
    </Helmet>
  );
};

export default SEO;