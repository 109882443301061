/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { format, startOfMonth, eachDayOfInterval, endOfMonth, isToday, isBefore, startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../components/ui/dialog';
import { Calendar as Loader2, ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '../lib/utils';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] as const;
const BUSINESS_HOURS = {
  start: 9,
  end: 17,
};

interface TimeSlot {
  time: string;
  available: boolean;
}

const Booking = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<TimeSlot[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const navigate = useNavigate();
  
console.log(selectedTime);
  // Generate time slots for the selected date
  const generateTimeSlots = async (date: Date) => {
    setIsLoading(true);
    try {
      // Mock API call to Teams - replace with your actual Teams API integration
      const teamsAvailability = await fetchTeamsAvailability(date);
      
      const slots: TimeSlot[] = [];
      for (let hour = BUSINESS_HOURS.start; hour < BUSINESS_HOURS.end; hour++) {
        for (const minute of [0, 30]) { // 30-minute slots
          const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
          
          // Check if this slot is available in Teams
          const isAvailable = teamsAvailability.some(slot => 
            slot.startTime === timeString && slot.available
          );
          
          slots.push({
            time: timeString,
            available: isAvailable,
          });
        }
      }

      // If the day is completely free, block 40% of slots randomly
      if (slots.every(slot => slot.available)) {
        const slotsToBlock = Math.floor(slots.length * 0.4);
        const shuffled = [...slots].sort(() => 0.5 - Math.random());
        
        for (let i = 0; i < slotsToBlock; i++) {
          const index = slots.findIndex(slot => slot.time === shuffled[i].time);
          if (index !== -1) {
            slots[index].available = false;
          }
        }
      }

      setAvailableSlots(slots);
    } catch (error) {
      console.error('Error fetching availability:', error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  // Mock function - replace with actual Teams API call
  const fetchTeamsAvailability = async (date: Date) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Mock response - replace with actual Teams data
    return Array.from({ length: 16 }, (_, i) => ({
      startTime: `${(i + 9).toString().padStart(2, '0')}:00`,
      available: Math.random() > 0.3,
    }));
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setTimeModalOpen(true);
    generateTimeSlots(date);
  };

  const handleTimeSelection = (time: string) => {
    setSelectedTime(time);
    setTimeModalOpen(false);
    navigate('/booking/confirm', {
      state: { date: selectedDate, time }
    });
  };

  const nextMonth = () => {
    setCurrentMonth(month => new Date(month.setMonth(month.getMonth() + 1)));
  };

  const previousMonth = () => {
    setCurrentMonth(month => new Date(month.setMonth(month.getMonth() - 1)));
  };

  // Get calendar days including padding days
  const calendarDays = () => {
    const start = startOfWeek(startOfMonth(currentMonth));
    const end = endOfWeek(endOfMonth(currentMonth));
    return eachDayOfInterval({ start, end });
  };

  // The function that handles the click event
  const goToToday = () => {
    const today = new Date();
    setCurrentMonth(today); // Reset the calendar view to current month
    setSelectedDate(today); // Optionally select today's date
  };

  // Get translated weekdays or fallback to English
  const weekDays = t('booking.weekDays.short', { 
    returnObjects: true,
    defaultValue: WEEKDAYS 
  }) as string[];

  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.pages.booking.title")}
      descriptionKey={t("seo.pages.booking.description")}
      path="/booking"
    />
    <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white pt-16">
      <div className="container mx-auto px-4 py-20">
        <h1 className="text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-400">
          {t('booking.title')}
        </h1>

        <div className="mb-12">
          <div className="bg-slate-900/30 backdrop-blur-sm rounded-xl p-8 border border-cyan-500/10 shadow-lg">
            <h2 className="text-2xl font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-400">
              {t('booking.intro.title', 'Experience the Future of Business Technology')}
            </h2>
            
            <p className="text-gray-300 mb-6 leading-relaxed">
              {t('booking.intro.description', 'Join us for a personalized, no-pressure 30-minute demo where we\'ll explore how our cutting-edge solutions can revolutionize your business operations.')}
            </p>

            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-cyan-400">
                  {t('booking.intro.duringDemo')}
                </h3>
                <ul className="space-y-3">
                  {(t('booking.intro.demoFeatures', {
                    returnObjects: true,
                    defaultValue: [
                      "Custom walkthrough of features relevant to your business",
                      "Live demonstration with real-world scenarios",
                      "Interactive Q&A session",
                      "Tailored solutions discussion"
                    ]
                  }) as unknown as string[]).map((item: string, index: number) => (
                    <li key={index} className="flex items-start gap-3 text-gray-300">
                      <span className="text-cyan-400 mt-1">✦</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-medium text-cyan-400">
                  {t('booking.intro.whyBook')}
                </h3>
                <ul className="space-y-3">
                  {(t('booking.intro.benefits', {
                    returnObjects: true,
                    defaultValue: [
                      "100% free consultation",
                      "No commitment required",
                      "Expert insights for your industry",
                      "Personalized recommendations"
                    ]
                  }) as unknown as string[]).map((item: string, index: number) => (
                    <li key={index} className="flex items-start gap-3 text-gray-300">
                      <span className="text-cyan-400 mt-1">✦</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-slate-900/50 backdrop-blur-lg rounded-xl p-8 border border-gray-700/50 shadow-xl">
          {/* Calendar Header */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-semibold text-gray-200">
                {format(currentMonth, 'MMMM yyyy')}
              </h2>
              <Button
                variant="ghost"
                size="sm"
                onClick={goToToday}
                className="text-cyan-400 hover:text-cyan-300"
              >
                {t('booking.today')}
              </Button>
            </div>
            <div className="flex gap-2">
              <button
                onClick={previousMonth}
                className="p-2 hover:bg-gray-700/50 rounded-full transition-colors"
              >
                <ChevronLeft className="h-5 w-5 text-gray-400" />
              </button>
              <button
                onClick={nextMonth}
                className="p-2 hover:bg-gray-700/50 rounded-full transition-colors"
              >
                <ChevronRight className="h-5 w-5 text-gray-400" />
              </button>
            </div>
          </div>

          {/* Weekday Headers */}
          <div className="grid grid-cols-7 gap-4 mb-4">
            {weekDays.map((day: string) => (
              <div key={day} className="text-center font-medium text-gray-400 text-sm">
                {day}
              </div>
            ))}
          </div>

          {/* Calendar Grid */}
          <div className="grid grid-cols-7 gap-4">
            {calendarDays().map((date, i) => {
              const dayNumber = date.getDate();
              const isCurrentDay = isToday(date);
              const isSelected = date.toDateString() === selectedDate.toDateString();
              const isPastDay = isBefore(date, new Date()) && !isCurrentDay;
              const isCurrentMonth = date.getMonth() === currentMonth.getMonth();
              
              return (
                <button 
                  key={i}
                  onClick={() => !isPastDay && handleDateSelect(date)}
                  disabled={isPastDay}
                  className={cn(
                    "aspect-square relative rounded-lg p-2 transition-all duration-200",
                    "hover:bg-gray-700/50 hover:scale-105",
                    "focus:outline-none focus:ring-2 focus:ring-cyan-400/50",
                    "disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100",
                    {
                      "bg-cyan-500/10 text-cyan-400 font-semibold": isCurrentDay,
                      "bg-cyan-500/20 ring-2 ring-cyan-400 font-bold": isSelected,
                      "text-gray-500": !isCurrentMonth,
                      "hover:bg-transparent": isPastDay,
                    }
                  )}
                >
                  <span className={cn(
                    "absolute inset-0 flex items-center justify-center text-sm",
                    isSelected && "text-cyan-400"
                  )}>
                    {dayNumber}
                  </span>
                  {isCurrentDay && (
                    <span className="absolute bottom-1 left-1/2 -translate-x-1/2 w-1 h-1 bg-cyan-400 rounded-full" />
                  )}
                </button>
              );
            })}
          </div>

          {/* Time Slots Modal */}
          <Dialog open={timeModalOpen} onOpenChange={setTimeModalOpen}>
            <DialogContent className="bg-gray-800/95 backdrop-blur-lg border-gray-700">
              <DialogHeader>
                <DialogTitle className="text-xl font-semibold text-white">
                  {t('booking.availableTimes')} {format(selectedDate, 'MMMM d, yyyy')}
                </DialogTitle>
              </DialogHeader>
              
              {isLoading ? (
                <div className="flex items-center justify-center py-8">
                  <Loader2 className="h-8 w-8 animate-spin text-cyan-400" />
                  <span className="ml-2 text-cyan-400">{t('booking.loading')}</span>
                </div>
              ) : (
                <div className="grid grid-cols-4 gap-3">
                  {availableSlots.map((slot) => (
                    <button
                      key={slot.time}
                      onClick={() => slot.available && handleTimeSelection(slot.time)}
                      disabled={!slot.available}
                      className={cn(
                        "py-2 px-4 rounded-lg transition-all duration-200",
                        "text-sm font-medium",
                        slot.available 
                          ? "bg-cyan-500/20 text-cyan-400 hover:bg-cyan-500/30 hover:scale-105" 
                          : "bg-gray-700/50 text-gray-400 cursor-not-allowed"
                      )}
                    >
                      {slot.time}
                    </button>
                  ))}
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
    </> 
  );
};

export default Booking;