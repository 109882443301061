/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const api = axios.create({
  baseURL: '/'
});

interface ContactMessage {
  name: string;
  email: string;
  message: string;
}

export const contactService = {
  sendMessage: async (message: ContactMessage): Promise<void> => {
    try {
      await api.post('/contact', message);
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }
};

