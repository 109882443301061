import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { useTranslatedSubjects } from '../hooks/useTranslatedSubjects';
import { bookingService } from '../services/bookingService';
import { Loader2 } from 'lucide-react';
import { useFormattedDate } from '../hooks/useFormattedDate';

interface LocationState {
  date: Date;
  time: string;
}

const BookingConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { date, time } = location.state as LocationState;
  const translatedSubjects = useTranslatedSubjects();
  const formatDate = useFormattedDate();

  const [formData, setFormData] = useState({
    subject: '',
    notes: '',
    customerName: '',
    customerEmail: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const selectedDateTime = new Date(date);
    const [hours, minutes] = time.split(':').map(Number);
    selectedDateTime.setHours(hours, minutes);

    if (selectedDateTime < new Date()) {
      setError(t('booking.error.invalidDateTime'));
    }
  }, [date, time, t]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const selectedDateTime = new Date(date);
    const [hours, minutes] = time.split(':').map(Number);
    selectedDateTime.setHours(hours, minutes);

    if (selectedDateTime < new Date()) {
      setError(t('booking.error.invalidDateTime'));
      setIsSubmitting(false);
      return;
    }

    try {
      await bookingService.createBooking({
        date: date.toISOString(),
        time,
        ...formData
      });

      navigate('/booking/success', {
        state: {
          date,
          time,
          subject: translatedSubjects.find(s => s.id === formData.subject)?.title,
          customerName: formData.customerName,
        }
      });
    } catch (error) {
      console.error('Booking error:', error);
      setError(t('booking.error.submission'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white pt-16">
      <div className="container mx-auto px-4 py-20">
        <h1 className="text-4xl font-bold mb-8 pb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-400">
          {t('booking.confirm.title')}
        </h1>

        <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-8 border border-gray-700/50 shadow-xl max-w-3xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Selected Date and Time Summary */}
            <div className="grid grid-cols-2 gap-6 p-4 bg-gray-700/30 rounded-lg">
              <div>
                <Label className="text-gray-400">
                  {t('booking.form.date')}
                </Label>
                <p className="text-lg font-medium text-white">
                  {formatDate(date, 'PPPP')}
                </p>
              </div>
              <div>
                <Label className="text-gray-400">
                  {t('booking.form.time')}
                </Label>
                <p className="text-lg font-medium text-white">{time}</p>
              </div>
            </div>

            {/* Demo Subject Selection */}
            <div className="space-y-4">
              <Label className="text-lg">
                {t('booking.form.subject')}
              </Label>
              <RadioGroup
                value={formData.subject}
                onValueChange={(value) => 
                  setFormData(prev => ({ ...prev, subject: value }))
                }
                className="grid grid-cols-1 gap-4"
              >
                {translatedSubjects.map((subject) => (
                  <div 
                    key={subject.id}
                    className={`
                      p-4 rounded-lg border transition-all duration-200
                      ${formData.subject === subject.id 
                        ? 'border-cyan-400 bg-cyan-400/10' 
                        : 'border-gray-700 hover:border-gray-600'
                      }
                    `}
                  >
                    <RadioGroupItem 
                      value={subject.id} 
                      id={subject.id}
                      className="peer sr-only"
                    />
                    <Label 
                      htmlFor={subject.id} 
                      className="flex flex-col cursor-pointer"
                    >
                      <span className="text-lg font-medium">
                        {subject.title}
                      </span>
                      <span className="text-sm text-gray-400">
                        {subject.description}
                      </span>
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </div>

            {/* Contact Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-2">
                <Label htmlFor="customerName">
                  {t('booking.form.customerName')}
                </Label>
                <Input
                  id="customerName"
                  value={formData.customerName}
                  onChange={(e) => 
                    setFormData(prev => ({ 
                      ...prev, 
                      customerName: e.target.value 
                    }))
                  }
                  required
                  className="bg-gray-700/50 border-gray-600 focus:border-cyan-400"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="customerEmail">
                  {t('booking.form.customerEmail')}
                </Label>
                <Input
                  id="customerEmail"
                  type="email"
                  value={formData.customerEmail}
                  onChange={(e) => 
                    setFormData(prev => ({ 
                      ...prev, 
                      customerEmail: e.target.value 
                    }))
                  }
                  required
                  className="bg-gray-700/50 border-gray-600 focus:border-cyan-400"
                />
              </div>
            </div>

            {/* Additional Notes */}
            <div className="space-y-2">
              <Label htmlFor="notes">
                {t('booking.form.notes')}
              </Label>
              <Textarea
                id="notes"
                value={formData.notes}
                onChange={(e) => 
                  setFormData(prev => ({ 
                    ...prev, 
                    notes: e.target.value 
                  }))
                }
                className="bg-gray-700/50 border-gray-600 focus:border-cyan-400 min-h-[100px]"
                placeholder={t('booking.form.notesPlaceholder')}
              />
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-red-400 text-sm p-3 bg-red-400/10 rounded-lg">
                {error}
              </div>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              disabled={isSubmitting || !formData.subject}
              className="w-full md:w-auto"
            >
              {isSubmitting ? (
                <span className="flex items-center gap-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  {t('booking.confirm.submitting')}
                </span>
              ) : (
                t('booking.confirm.submit')
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation; 