import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Services from './pages/Services';
import Booking from './pages/Booking';
import About from './pages/About';
import Contact from './pages/Contact';
import './i18n';
import { Suspense } from 'react';
import { Loader2 } from 'lucide-react';
import BookingConfirmation from './pages/BookingConfirmation';
import BookingSuccess from './pages/BookingSuccess';
import SharePointDev from './pages/SharePointDev';
import PowerAppsDev from './pages/PowerAppsDev';
import PowerAutomate from './pages/PowerAutomate';
import DocumentManagement from './pages/DocumentManagement';
import AIIntegration from './pages/AIIntegration';
import Microsoft365Consulting from './pages/Microsoft365Consulting';
import ScrollToTop from './components/ScrollToTop';
import CookieConsent from './components/CookieConsent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/SEO';
import Prices from './pages/Prices';
import PriceInquirySuccess from './pages/PriceInquirySuccess';

// Custom loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin text-cyan-400" />
  </div>
);

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <SEO />
      <BrowserRouter>
        <ScrollToTop />
      <Suspense fallback={<LoadingSpinner />}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/booking/confirm" element={<BookingConfirmation />} />
            <Route path="/booking/success" element={<BookingSuccess />} />
            <Route path="/services/sharepoint-development" element={<SharePointDev />} />
            <Route path="/services/power-apps" element={<PowerAppsDev />} />
            <Route path="/services/power-automate" element={<PowerAutomate />} />
            <Route path="/services/document-management" element={<DocumentManagement />} />
            <Route path="/services/ai-integration" element={<AIIntegration />} />
            <Route path="/services/microsoft-365" element={<Microsoft365Consulting />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/price-inquiry-success" element={<PriceInquirySuccess />} />
          </Routes>
        </Layout>
      </Suspense>
        <CookieConsent />
      </BrowserRouter>
    </HelmetProvider>
  );  
};

export default App;
