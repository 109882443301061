import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  AlertCircle, 
  Workflow,
  Check, 
  Clock,
  FileQuestion,
  Zap,
  RefreshCw,
  Bot,
  Cog,
  ArrowRightLeft,
  Shield,
  Gauge
} from 'lucide-react';
import { Card } from './ui/card';

interface StepContentProps {
  stepIndex: number;
}

const PowerAutomateFlow: React.FC = () => {
  const [step, setStep] = useState(0);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prev) => (prev + 1) % 3);
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  const steps = [
    {
      title: "The Challenge",
      description: "Manual & repetitive tasks",
      icon: AlertCircle,
      color: "text-red-500",
      bgColor: "bg-red-500/10",
      borderColor: "border-red-500/20"
    },
    {
      title: "The Solution",
      description: "Automated workflow implementation",
      icon: Workflow,
      color: "text-blue-500",
      bgColor: "bg-blue-500/10",
      borderColor: "border-blue-500/20"
    },
    {
      title: "The Result",
      description: "Efficient automated processes",
      icon: Check,
      color: "text-green-500",
      bgColor: "bg-green-500/10",
      borderColor: "border-green-500/20"
    }
  ];

  const StepContent: React.FC<StepContentProps> = ({ stepIndex }) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="grid grid-cols-3 gap-4 mt-6">
            {[
              { icon: Clock, label: "Time Waste", problem: "Manual Tasks" },
              { icon: FileQuestion, label: "Error-Prone", problem: "Human Mistakes" },
              { icon: Gauge, label: "Low Efficiency", problem: "Slow Processing" }
            ].map((item, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: i * 0.2 }}
                className="flex flex-col items-center p-4 bg-gray-800/50 rounded-lg border border-gray-700"
              >
                <item.icon className="w-8 h-8 text-red-400 mb-2" />
                <span className="text-sm text-gray-300 text-center">{item.label}</span>
                <span className="text-xs text-red-400 mt-1">{item.problem}</span>
              </motion.div>
            ))}
          </div>
        );
      
      case 1:
        return (
          <div className="relative mt-6">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex justify-center items-center"
            >
              <div className="relative w-full max-w-lg">
                <motion.div
                  animate={{
                    scale: [1, 1.05, 1],
                    rotate: [0, 2, -2, 0],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 w-32 h-32 bg-blue-500/20 rounded-full blur-xl"
                />
                <Card className="relative p-6 bg-gray-800/50 border-gray-700">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2 text-blue-400">
                        <Bot className="w-5 h-5" />
                        <span>RPA Integration</span>
                      </div>
                      <div className="flex items-center space-x-2 text-blue-400">
                        <ArrowRightLeft className="w-5 h-5" />
                        <span>Custom Flows</span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2 text-blue-400">
                        <RefreshCw className="w-5 h-5" />
                        <span>Auto-Triggers</span>
                      </div>
                      <div className="flex items-center space-x-2 text-blue-400">
                        <Cog className="w-5 h-5" />
                        <span>Smart Actions</span>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </motion.div>
          </div>
        );
      
      case 2:
        return (
          <div className="grid grid-cols-3 gap-4 mt-6">
            {[
              { icon: Zap, label: "Rapid Execution", metric: "90% Faster" },
              { icon: Shield, label: "Error Reduction", metric: "99% Accuracy" },
              { icon: RefreshCw, label: "24/7 Operation", metric: "100% Uptime" }
            ].map((item, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: i * 0.2 }}
                className="flex flex-col items-center p-4 bg-gray-800/50 rounded-lg border border-gray-700"
              >
                <item.icon className="w-8 h-8 text-green-400 mb-2" />
                <span className="text-sm text-gray-300 text-center">{item.label}</span>
                <span className="text-xs text-green-400 mt-1">{item.metric}</span>
              </motion.div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full bg-gray-900 p-6 rounded-xl border border-gray-800">
      <div className="flex justify-between mb-8">
        {steps.map((s, i) => (
          <motion.div
            key={i}
            className={`flex flex-col items-center w-1/3 ${
              i === step ? s.color : 'text-gray-500'
            }`}
          >
            <div
              className={`w-12 h-12 rounded-full ${
                i === step ? s.bgColor : 'bg-gray-800'
              } ${
                i === step ? s.borderColor : 'border-gray-700'
              } border-2 flex items-center justify-center mb-2`}
            >
              <s.icon className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium">{s.title}</span>
            <span className="text-xs text-gray-400 text-center mt-1">
              {s.description}
            </span>
          </motion.div>
        ))}
      </div>

      <motion.div
        key={step}
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{ duration: 0.5 }}
      >
        <StepContent stepIndex={step} />
      </motion.div>
    </div>
  );
};

export default PowerAutomateFlow; 