import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        titleKey="Privacy Policy | Intra Solutions" 
        descriptionKey="Privacy and cookie policy for Intra Solutions"
        path="/privacy-policy"
        defaultTitle="Privacy Policy | Intra Solutions"
        defaultDescription="Privacy and cookie policy for Intra Solutions"
      />
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white">
        <div className="container mx-auto px-4 py-24 max-w-4xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold mb-8 pb-3 bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600">Privacy Policy</h1>
            
            <section className="mb-8 bg-gray-800/30 backdrop-blur-lg rounded-xl p-6 border border-gray-700/50">
              <h2 className="text-2xl font-semibold mb-4 text-cyan-400">Cookie Policy</h2>
              <p className="mb-4 text-gray-300">
                Our website uses cookies to enhance your browsing experience and help us improve our services.
                Cookies are small text files that are stored on your device when you visit our website.
              </p>
              
              <h3 className="text-xl font-semibold mb-2 text-cyan-400">Essential Cookies</h3>
              <p className="mb-4 text-gray-300">
                These cookies are necessary for the website to function properly. They enable basic functions
                like page navigation and access to secure areas of the website.
              </p>
              
              <h3 className="text-xl font-semibold mb-2 text-cyan-400">Analytics Cookies</h3>
              <p className="mb-4 text-gray-300">
                We use analytics cookies to understand how visitors interact with our website. This helps us
                improve our website and services. The information collected is anonymous and statistical.
              </p>
            </section>

            <section className="mb-8 bg-gray-800/30 backdrop-blur-lg rounded-xl p-6 border border-gray-700/50">
              <h2 className="text-2xl font-semibold mb-4 text-cyan-400">Your Rights</h2>
              <p className="mb-4 text-gray-300">
                You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
                but you can usually modify your browser settings to decline cookies if you prefer.
              </p>
            </section>

            <section className="bg-gray-800/30 backdrop-blur-lg rounded-xl p-6 border border-gray-700/50">
              <h2 className="text-2xl font-semibold mb-4 text-cyan-400">Contact Us</h2>
              <p className="text-gray-300">
                If you have any questions about our privacy policy or cookie usage, please contact us.
              </p>
            </section>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;