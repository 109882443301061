import axios from 'axios';

const api = axios.create({
  baseURL: '/'
});

interface NewsletterSubscription {
  email: string;
}

export const newsletterService = {
  subscribe: async (subscription: NewsletterSubscription): Promise<void> => {
    try {
      await api.post('/newsletter/subscribe', subscription);
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      throw error;
    }
  }
};