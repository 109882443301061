import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import NetworkAnimation from '../components/NetworkAnimation';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';
const Microsoft365Consulting: React.FC = () => {
  const { t } = useTranslation();

  // Fetch strings from the language file
  const serviceName = t('seo.services.microsoft365.title');
  const benefit = t('seo.services.microsoft365.description');

  // Function to generate a dynamic description
  function generateDynamicDescription(serviceName: string, benefit: string): string {
    return `Discover our ${serviceName} services at Intra Solutions. ${benefit} Enhance your business with our expert solutions.`;
  }

  // Generate the dynamic description
  const dynamicDescription = generateDynamicDescription(serviceName, benefit);

  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.services.microsoft365.title")}
      descriptionKey={dynamicDescription}
      path="/services/microsoft-365"
    />
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-3xl" />
        <div className="container mx-auto px-4 py-20 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 mb-5 pb-3">
              {t('services.microsoft365.title')}
            </h1>
            <p className="text-xl md:text-2xl text-gray-300 leading-relaxed mb-8">
              {t('services.microsoft365.subtitle')}
            </p>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        {/* Animated Features */}
        <div className="flex justify-center mb-20">
          <NetworkAnimation />
        </div>

        {/* Information Sections */}
        <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12 mb-20">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <h2 className="text-3xl font-bold text-cyan-400">{t('services.microsoft365.whyChoose.title')}</h2>
            <div className="space-y-4">
              <p className="text-gray-300">{t('services.microsoft365.whyChoose.description')}</p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                {(t('services.microsoft365.whyChoose.items', { returnObjects: true }) as string[]).map((item: string, index: number) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-6"
          >
            <h2 className="text-3xl font-bold text-cyan-400">{t('services.microsoft365.expertise.title')}</h2>
            <div className="space-y-4">
              <p className="text-gray-300">{t('services.microsoft365.expertise.description')}</p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                {(t('services.microsoft365.expertise.items', { returnObjects: true }) as string[]).map((item: string, index: number) => (
                  <li key={index}>{item}</li>
                ))} 
              </ul>
            </div>
          </motion.div>
        </div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="max-w-4xl mx-auto text-center bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-blue-500/10 p-12 rounded-2xl border border-gray-700/50 backdrop-blur-sm"
        >
          <h2 className="text-3xl font-bold text-white mb-6">{t('services.microsoft365.cta.title')}</h2>
          <p className="text-xl text-gray-300 mb-8">
            {t('services.microsoft365.cta.description')}
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transition-all group"
          >
            {t('services.microsoft365.cta.button')}
            <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </Link>
        </motion.div>
      </div>
    </div>
    </> 
  );
};

export default Microsoft365Consulting; 