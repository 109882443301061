import React, { useEffect, useState } from 'react';
import { Button } from "./ui/button";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const acceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setIsVisible(false);
  };

  const acceptEssential = () => {
    localStorage.setItem('cookieConsent', 'essential');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div 
      role="alert"
      aria-live="polite"
      className="fixed bottom-0 left-0 right-0 bg-gray-900/95 text-white p-4 shadow-lg backdrop-blur-sm z-50"
      data-nosnippet
    >
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-4">
        <div className="text-sm">
          <p className="mb-2">
            {t('cookies.message')}
            <Link to="/privacy-policy" className="underline ml-1">
              {t('cookies.learnMore')}
            </Link>
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-2">
          <Button
            variant="outline"
            onClick={acceptEssential}
            className="text-gray-700 hover:text-gray-900"
          >
            {t('cookies.acceptEssential')}
          </Button>
          <Button
            onClick={acceptAll}
            className="bg-primary hover:bg-primary/90"
          >
            {t('cookies.acceptAll')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 