import React from 'react';
import { Link } from 'react-router-dom';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { Button } from "./ui/button"
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "./ui/sheet"
import { Menu } from 'lucide-react';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  const scrollPosition = useScrollPosition();

  const navigation = [
    { name: t('navigation.home'), href: '/' },
    { name: t('navigation.services'), href: '/services' },
    { name: t('navigation.prices'), href: '/prices' },
    { name: t('navigation.about'), href: '/about' },
    { name: t('navigation.contact'), href: '/contact' }
  ];

  return (
    <nav className={`fixed w-full transition-all duration-300 z-50 ${
      scrollPosition > 0 
        ? 'bg-gray-900/95 from-purple-900/20 bg-gradient-to-r shadow-lg backdrop-blur-sm'
        : 'bg-transparent -translate-y-1'
    }`}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link
              to="/"
              className="flex items-center gap-3"
            >
              <img 
                src="/intra-solutions-icon.png" 
                alt="Intra Solutions Logo" 
                className="w-6 h-8 md:w-16 md:h-9"
              />
              <span className={`text-xl md:text-2xl font-bold ${
                scrollPosition > 0 
                  ? 'text-white'
                  : 'text-white'
              }`}>
                Intra Solutions
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`px-3 py-2 text-sm font-medium transition-colors ${
                    scrollPosition > 0 
                      ? 'text-gray-300 hover:text-white'
                      : 'text-gray-300 hover:text-white'
                  }`}
                >
                  {item.name}
                </Link>
              ))}
              <Link to="/booking">
                <Button 
                  variant="default" 
                  className="bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white shadow-lg hover:shadow-xl transition-all duration-200 font-medium"
                >
                  {t('navigation.bookDemo')}
                </Button>
              </Link>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Menu className="h-6 w-6 text-white" />
                </Button>
              </SheetTrigger>
              <SheetContent>
                <div className="mt-6 flow-root">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="block px-3 py-2 text-base font-medium text-foreground hover:bg-accent"
                      >
                        {item.name}
                      </Link>
                    ))}
                    <Link
                      to="/booking"
                      className="block px-3 py-2 text-base font-medium text-indigo-500 hover:text-indigo-400 hover:bg-accent"
                    >
                      {t('navigation.bookDemo')}
                    </Link>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>

          <div className="flex items-center gap-4">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 