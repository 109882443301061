/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

const api = axios.create({
  baseURL: '/'
});

export const bookingService = {
  createBooking: async (booking: any): Promise<void> => {
    try {
      await api.post('/booking/create', booking);
    } catch (error) {
      console.error('Error creating booking:', error);
      throw error;
    }
  }
}; 