import React, { useState } from 'react';
import { contactService } from '../services/contactService';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
    
    try {
      await contactService.sendMessage(formData);
      setStatus('success');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  };

  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.pages.contact.title")}
      descriptionKey={t("seo.pages.contact.description")}
      path="/contact"
    />
    <div className="min-h-screen bg-gradient-to-b from-slate-950 to-slate-900 text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 pb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            {t('contact.title')}
          </h1>
          <h4 className="text-xl md:text-2xl mb-8 text-center text-gray-400">
            {t('contact.subtitle')}
          </h4>
          <div className="backdrop-blur-lg bg-slate-900/50 rounded-2xl p-8 shadow-2xl">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <label htmlFor="name" className="text-sm font-medium text-blue-300 block mb-2">
                  {t('contact.form.name')}
                </label>
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                  placeholder={t('contact.form.namePlaceholder')}
                  required
                />
              </div>
              
              <div className="relative">
                <label htmlFor="email" className="text-sm font-medium text-blue-300 block mb-2">
                  {t('contact.form.email')}
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                  placeholder={t('contact.form.emailPlaceholder')}
                  required
                />
              </div>
              
              <div className="relative">
                <label htmlFor="message" className="text-sm font-medium text-blue-300 block mb-2">
                  {t('contact.form.message')}
                </label>
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  className="w-full bg-gray-800/50 border border-gray-700 text-white rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                  placeholder={t('contact.form.messagePlaceholder')}
                  required
                ></textarea>
              </div>
              
              <button
                type="submit"
                disabled={status === 'sending'}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-lg font-medium hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200 disabled:opacity-50"
              >
                {status === 'sending' ? t('contact.form.sending') : t('contact.form.sendMessage')}
              </button>
            </form>

            {status === 'success' && (
              <div className="mt-4 p-4 bg-green-500/20 border border-green-500 rounded-lg text-green-300">
                {t('contact.form.success')}
              </div>
            )}
            
            {status === 'error' && (
              <div className="mt-4 p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300">
                {t('contact.form.error')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </> 
  );
};

export default Contact; 