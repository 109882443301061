import React, { useEffect, useRef } from 'react';

const NetworkAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  interface Particle {
    x: number;
    y: number;
    vx: number;
    vy: number;
    connections: number[];
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas size to match window size
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const particles: Particle[] = [];
    const particleCount = 50;
    const connectionDistance = 150;
    const particleRadius = 2;

    // Create particles
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        vx: (Math.random() - 0.5) * 0.5,
        vy: (Math.random() - 0.5) * 0.5,
        connections: []
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Update particle positions
      particles.forEach((particle, i) => {
        particle.x += particle.vx;
        particle.y += particle.vy;

        // Bounce off walls
        if (particle.x < 0 || particle.x > canvas.width) particle.vx *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.vy *= -1;

        // Draw particle
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particleRadius, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(64, 224, 208, 0.8)'; // Turquoise color
        ctx.fill();

        // Reset connections
        particle.connections = [];

        // Check for connections with other particles
        particles.forEach((otherParticle, j) => {
          if (i === j) return;

          const dx = otherParticle.x - particle.x;
          const dy = otherParticle.y - particle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < connectionDistance) {
            particle.connections.push(j);

            // Draw connection line
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            
            // Create gradient for the line
            const gradient = ctx.createLinearGradient(
              particle.x, particle.y, 
              otherParticle.x, otherParticle.y
            );
            const opacity = 1 - (distance / connectionDistance);
            gradient.addColorStop(0, `rgba(64, 224, 208, ${opacity * 0.5})`);
            gradient.addColorStop(1, `rgba(64, 224, 208, 0)`);
            
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 1;
            ctx.stroke();

            // Occasionally create a "pulse" effect
            if (Math.random() < 0.001) {
              const pulse = (progress: number) => {
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, progress * connectionDistance, 0, Math.PI * 2);
                ctx.strokeStyle = `rgba(64, 224, 208, ${(1 - progress) * 0.3})`;
                ctx.stroke();
              };

              let progress = 0;
              const pulseInterval = setInterval(() => {
                progress += 0.05;
                if (progress >= 1) clearInterval(pulseInterval);
                pulse(progress);
              }, 20);
            }
          }
        });
      });

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full pointer-events-none"
      style={{ zIndex: 0 }}
    />
  );
};

export default NetworkAnimation; 