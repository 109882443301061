import axios from 'axios';

interface PriceInquiry {
  name: string;
  email: string;
  company: string;
  employees: string;
  message: string;
  selectedService: string;
}

export const priceInquiryService = {
  submitInquiry: async (inquiry: PriceInquiry): Promise<void> => {
    try {
      await axios.post('/price-inquiry', inquiry);
    } catch (error) {
      console.error('Error submitting price inquiry:', error);
      throw error;
    }
  }
}; 