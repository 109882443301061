import React from 'react';
import AIFlow from '../components/AIFlow';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO';

const AIIntegration: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
    {/* SEO Configuration */}
    <SEO 
      titleKey={t("seo.services.aiIntegration.title")}
      descriptionKey={t("seo.services.aiIntegration.description")}
      path="/services/ai-integration"
    />

    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-blue-500/10 backdrop-blur-3xl" />
        <div className="container mx-auto px-4 py-20 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h1 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 mb-5 pb-3">
            {t('services.aiIntegration.title')}
            </h1>
            <p className="text-xl md:text-2xl text-gray-300 leading-relaxed mb-8">
              {t('services.aiIntegration.subtitle')}
            </p>
            <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-400">
              {[
                "TensorFlow",
                "PyTorch",
                "Natural Language Processing",
                "Machine Learning",
                "Computer Vision",
                "Deep Learning"
              ].map((tech, index) => (
                <span
                  key={index}
                  className="px-4 py-2 rounded-full bg-gray-800/50 border border-gray-700"
                >
                  {tech}
                </span>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <h2 className="text-3xl font-bold mb-6 text-center text-cyan-400">
            {t('services.aiIntegration.process.title')}
          </h2>
          <p className="text-gray-300 text-center mb-12">
            {t('services.aiIntegration.process.description')}
          </p>
          
          {/* AI Flow Component */}
          <AIFlow />
        </motion.div>

        {/* Key Benefits */}
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mt-20">
          {[
            {
              title: t('services.aiIntegration.benefits.automation.title'),
              description: t('services.aiIntegration.benefits.automation.description'),
              icon: "🤖"
            },
            {
              title: t('services.aiIntegration.benefits.analytics.title'),
              description: t('services.aiIntegration.benefits.analytics.description'),
              icon: "📊"
            },
            {
              title: t('services.aiIntegration.benefits.experience.title'),
              description: t('services.aiIntegration.benefits.experience.description'),
              icon: "💬"
            }
          ].map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
              className="bg-gray-800/30 backdrop-blur-lg rounded-xl p-6 border border-gray-700/50"
            >
              <div className="text-4xl mb-4">{benefit.icon}</div>
              <h3 className="text-xl font-semibold mb-3 text-cyan-400">{benefit.title}</h3>
              <p className="text-gray-400">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
    </> 
  );
};

export default AIIntegration; 